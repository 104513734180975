<template>
  <div>
    <main class="bg-gray-col">
      <section class="header-space"></section>
      <b-container fluid class="">
        <b-row class="justify-content-center">
          <b-col cols="10" md="8" class="bg-white rounded mt-5 mb-4 pt-5 pb-4">
            <b-row class="justify-content-center">
              <b-col cols="10" sm="8" lg="6">
                <div class="text-center mb-3">
                  <svg width="3rem" height="3rem" viewBox="0 0 16 16" class="bi bi-check-circle" fill="#66bb87" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                  </svg>
                </div>
                <p class="mb-3 text-center proxima-bold frs-24 frm-28 frl-32 frxl-36">Your application has been submitted successfully!</p>
                <p class="mb-3 text-center proxima frs-16 frm-20 frl-24">You can regularly check the status on your dashboard and get notified for any update</p>
                <div class="text-center">
                  <button type="button" class="btn ls theme-bg-col text-white proxima-bold frm-16 frl-20">GO TO PROFILE</button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
export default {
  name: "Job-applied-successfully",
  components: {},
  data() {
    return {};
  },
};
</script>
<style>
</style>

